<template>
	<div>
		<h2 class="smaller mb-8 whitespace-nowrap">
			{{
				user && user.general && user.general.firstName
					? user && user.general && user.general.firstName
					: ''
			}}
			{{
				user && user.general && user.general.lastName
					? user && user.general && user.general.lastName
					: ''
			}}{{
				((user && user.general && user.general.firstName) ||
					(user && user.general && user.general.lastName)) &&
				user &&
				user.general &&
				user.general.birthday
					? `, ${calcAge(user && user.general && user.general.birthday)}`
					: ''
			}}
		</h2>
		<div class="mx-6 mb-3">
			<div class="text-start flex justify-between items-center mb-6">
				<h4 id="contact" class="mb-0">Kontaktdaten</h4>
				<HokIcon
					v-if="!viewOnly"
					name="icon:pencil"
					color="main"
					:size="5"
					pointer
					class="inline self-center"
					@click.stop="editCvGeneralData('contact')"
				/>
			</div>
			<div class="pb-6 border-b border-color-grey-lightest mb-6">
				<div class="flex space-x-2 mb-5">
					<HokIcon name="icon:letter-filled" color="grey" class="inline self-center mr-4" />
					<p
						v-if="user && user.general && user.general.email"
						class="mb-0 text-color-text text-start break-all"
					>
						{{ user.general.email }}
					</p>
					<p v-else class="mb-0 text-color-grey-medium">keine Angabe</p>

					<ToolTip
						v-if="user && user.general && user.general.email && !user.accountVerifiedEmail"
						icon="icon:alert"
						color="purple"
						:size="5"
						styling="user"
						vertical-align="align-text-bottom"
						text="Deine E-Mail-Adresse ist noch nicht verifiziert."
						custom-handler
						@click="showVerifyModal('email')"
					>
					</ToolTip>
				</div>
				<div class="flex space-x-2">
					<HokIcon name="icon:phone-filled" color="grey" class="inline self-center mr-4" />
					<p v-if="user && user.general && user.general.phone" class="mb-0 text-color-text">
						{{ user.general.phone }}
					</p>
					<p v-else class="mb-0 text-color-grey-medium">keine Angabe</p>
					<ToolTip
						v-if="user && user.general && user.general.phone && user && !user.accountVerifiedPhone"
						icon="icon:alert"
						color="purple"
						:size="5"
						styling="user"
						vertical-align="align-text-bottom"
						text="Deine Telefonnummer ist noch nicht verifiziert. Verifiziere sie, wenn du sie zum Anmelden verwenden willst."
						custom-handler
						@click="showVerifyModal('phone')"
					/>
				</div>
			</div>
			<div class="text-start flex justify-between items-center mb-6">
				<h4 id="personal" class="mb-0">Über mich</h4>
				<HokIcon
					v-if="!viewOnly"
					name="icon:pencil"
					color="main"
					:size="5"
					pointer
					class="inline self-center"
					@click.stop="editCvGeneralData('personal')"
				/>
			</div>

			<div class="pb-12 border-b border-color-grey-lightest">
				<div class="flex items-start space-x-6 mb-5 text-start break-all">
					<HokIcon name="icon:pin-2" color="grey" class="inline self-center" />
					<p
						v-if="
							user &&
							user.general &&
							user.general.address &&
							user.general.address.street &&
							user.general.address.code &&
							user.general.address.city
						"
						class="text-color-text mb-0"
					>
						{{ user.general.address.street }},{{ user.general.address.code }}
						{{ user.general.address.city }}
					</p>
					<p
						v-else-if="
							user &&
							user.general &&
							user.general.address &&
							user.general.address.street &&
							user.general.address.city
						"
						class="text-color-text mb-0"
					>
						{{ user.general.address.street }}, {{ user.general.address.city }}
					</p>
					<p
						v-else-if="
							user &&
							user.general &&
							user.general.address &&
							user.general.address.code &&
							user.general.address.city
						"
						class="text-color-text mb-0"
					>
						{{ user.general.address.code }} {{ user.general.address.city }}
					</p>
					<p
						v-else-if="user && user.general && user.general.address && user.general.address.city"
						class="text-color-text mb-0"
					>
						{{ user.general.address.city }}
					</p>
					<p v-else class="mb-0 text-color-grey-medium">keine Angabe</p>
				</div>
				<div class="flex space-x-6 mb-5 text-start">
					<HokIcon name="icon:globe" color="grey" class="inline self-center" />
					<p v-if="user && user.general && user.general.nationality" class="mb-0 text-color-text">
						{{ user.general.nationality }}
					</p>
					<p v-else class="mb-0 text-color-grey-medium">keine Angabe</p>
				</div>
				<div class="flex space-x-6 mb-0">
					<HokIcon name="icon:calendar" color="grey" class="inline self-center" />
					<p v-if="user && user.general && user.general.birthday" class="mb-0 text-color-text">
						{{ format('dd.MM.yyyy', user.general.birthday) }}
					</p>
					<p v-else class="mb-0 text-color-grey-medium">keine Angabe</p>
				</div>
			</div>
		</div>

		<!--user not verified yet - confirm via code-->

		<HokModal
			width="390px"
			:adaptive="true"
			:name="`user-not-verified-${myId}`"
			@opened="resendVerification(false)"
		>
			<h3 class="text-center">
				{{ contactType === 'email' ? 'E-Mail-Adresse' : 'Telefonnummer' }} bestätigen
			</h3>
			<p class="mb-2 text-color-text">
				Wir haben einen 4 stelligen Code an
				<strong v-if="contactType === 'email'" class="text-center block mb-2 sm:mt-2 break-all">{{
					user && user.general && user.general.email
				}}</strong>
				<strong v-else class="text-center block mb-2 sm:mt-2 break-all">{{
					user && user.general && user.general.phone
				}}</strong>
				gesendet. Bitte gib den Code ein, um deine
				{{ contactType === 'email' ? 'E-Mail-Adresse' : 'Telefonnummer' }} zu bestätigen.
			</p>
			<form class="text-center" @submit="verifyAccount($event)">
				<ErrorBox v-if="showInvalidCode"> Ungültiger Code! </ErrorBox>
				<HokInput
					id="verifycode"
					v-model="verifyCode"
					pattern="[0-9]*"
					styling="text-center"
					placeholder="Code hier eingeben"
					inputmode="numeric"
					name="code"
					:max-length="6"
					:min-length="4"
					:autofocus="true"
					class="mb-2"
					@change="codeChanged"
					@click="codeChanged"
				/>
				<HokButton id="submitverifycode" fullwidth="always" submit color="main"
					>Bestätigen</HokButton
				>
				<HokButton
					v-if="!resentVerification"
					id="resendverifycode"
					class="my-4"
					is-text
					color="business"
					@click="resendVerification(true)"
				>
					Code erneut zusenden
				</HokButton>
			</form>
		</HokModal>
	</div>
</template>

<script lang="ts">
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';
import { format } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/format';
import { calcAge } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/calculate-age';
import ToolTip from '@hokify/shared-components-nuxt3/lib/components/ToolTip.vue';
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useLoginStore } from '@/stores/login';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	components: {
		ErrorBox,
		HokInput,
		ToolTip
	},
	data() {
		return {
			format,
			calcAge,
			contactType: 'email',
			myId: Math.random(),
			verifyCode: '',
			showInvalidCode: false,
			resentVerification: false,
			loading: false,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to');
	},
	computed: {
		...mapStores(useLoginStore, useUserProfileStore)
	},
	methods: {
		async editCvGeneralData(type) {
			let pageTitle = '';
			if (type === 'contact') {
				pageTitle = 'Kontaktdaten bearbeiten';
			} else if (type === 'personal') {
				pageTitle = 'Persönliche Info bearbeiten';
			}
			const editCvGeneralDataComponent = markRaw(
				defineAsyncComponent(() => import('@/pages/pwa/profile/editCvGeneralData.vue'))
			);
			try {
				await this.$page.push(
					editCvGeneralDataComponent,
					{ type },
					{
						pageTitle,
						name: 'editCvGeneralData',
						done: () => {
							this.EventBus.$off('end-go-to');
						}
					}
				);
				this.EventBus.$on('end-go-to', () => this.$page.goBack());
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		showVerifyModal(type: string) {
			this.contactType = type;
			this.$modal.show(`user-not-verified-${this.myId}`);
		},
		async resendVerification(resend = false) {
			const url = 'profile';

			try {
				if (resend) {
					this.resentVerification = true;

					await this.$hokReCaptcha.sendWithRecaptchaToken(
						this.userProfileStore.sendVerificationCode,
						{
							entryUrl: url,
							verificationType: true
						},
						'verificationCode'
					);

					this.$snack.success({
						text: 'Code erneut versendet!'
					});
				} else {
					this.resentVerification = false;

					await this.$hokReCaptcha.sendWithRecaptchaToken(
						this.userProfileStore.sendVerificationCode,
						{
							entryUrl: url,
							verificationType: this.contactType
						},
						'verificationCode'
					);
				}
			} catch (err: any) {
				if (err.code === 'ALREADY_VERIFIED') {
					this.$snack.show({
						text: 'Dein Account ist bereits verifiziert.'
					});
				} else {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		async verifyAccount(e: Event) {
			if (e) {
				e.preventDefault();
			}

			if (!this.verifyCode) {
				this.showInvalidCode = true;
				return;
			}

			try {
				this.loading = true;
				await this.loginStore.verify(this.verifyCode);
				this.showInvalidCode = false;
				this.verifyCode = '';
				this.$modal.hide(`user-not-verified-${this.myId}`);
				this.$snack.success({
					text: 'Du hast dich erfolgreich verifiziert.'
				});
				this.$trackGenericEvent?.('user_verified', {});
			} catch (err: any) {
				this.loading = false;
				this.showInvalidCode = true;
			}
		},
		codeChanged() {
			this.showInvalidCode = false;
		}
	},
	props: {
		user: { type: Object as PropType<IAPILoginUser>, default: () => {} },
		viewOnly: { type: Boolean, default: false }
	}
});
</script>
