<template>
	<div>
		<!--upload form-->
		<Upload ref="upload" />

		<!-- let user choose between hokifyCv and PDFcv -->
		<ContextMenu
			v-if="showCvMenu"
			:menu-open="showCvMenu"
			placement="left-start"
			selector="#cv-edit-icon"
			@close="$emit('close')"
		>
			<div class="flex flex-col items-start text-start">
				<div class="mb-3 pb-3 border-b border-color-grey-lightest w-full">
					<HokButton v-if="visibleCv" :to="cvUrl" color="text" is-text>
						<HokIcon name="icon:download" :size="5" class="mr-5" />
						Lebenslauf herunterladen
					</HokButton>
				</div>
				<div v-if="!activeHokifyCv" class="mb-3 pb-3 border-b border-color-grey-lightest w-full">
					<HokButton color="text" is-text @click="switchCv('hokify')">
						<HokIcon name="icon:rectangles" :size="5" class="mr-5" />
						hokify Lebenslauf verwenden
					</HokButton>
				</div>
				<div v-else class="mb-3 pb-3 border-b border-color-grey-lightest w-full">
					<HokButton color="text" is-text @click="openChangeCvDesign">
						<HokIcon name="icon:color-palette" :size="5" class="mr-5" />
						Design ändern
					</HokButton>
				</div>
				<div class="mb-3 pb-3 border-b border-color-grey-lightest w-full">
					<HokButton color="text" is-text @click="switchCv('new-upload')">
						<HokIcon
							:name="activeHokifyCv ? 'icon:upload' : 'icon:refresh'"
							:size="5"
							class="mr-5"
						/>
						Lebenslauf {{ activeHokifyCv ? 'hochladen' : 'ersetzen' }}
					</HokButton>
				</div>
				<HokButton color="text" is-text @click="deleteCv">
					<HokIcon name="icon:trash" :size="5" class="mr-5" />
					Lebenslauf löschen
				</HokButton>
			</div>
		</ContextMenu>

		<!-- show warning, before really switching between hokifyCv and PDFcv -->
		<HokModal :adaptive="!!$isMobile.any" width="600px" name="chooseCvWarning" transition="scale">
			<div>
				<InfoBox v-if="cvType === 'hokify'" class="mb-8 mt-4">
					<template #title> Achtung! </template>
					Dein aktuelles Lebenslauf Dokument wird durch den hokify Lebenslauf ersetzt. Bist du
					sicher?
				</InfoBox>
				<InfoBox v-else class="mb-8 mt-4">
					<template #title> Achtung! </template>
					Dein Lebenslauf wird durch dein neues Lebenslauf Dokument ersetzt. Bist du sicher?
				</InfoBox>
				<HorizontalButtons>
					<template #left>
						<HokButton fullwidth="mobile" outline color="main" @click="cancelCvSelection">
							Abbrechen
						</HokButton>
					</template>
					<template #right>
						<HokButton fullwidth="mobile" @click="switchCv"> Fortfahren </HokButton>
					</template>
				</HorizontalButtons>
			</div>
		</HokModal>
	</div>
</template>
<script lang="ts">
import InfoBox from '@hokify/shared-components-nuxt3/lib/components/InfoBox.vue';
import HorizontalButtons from '@hokify/shared-components-nuxt3/lib/components/HorizontalButtons.vue';
import Upload from '@hokify/pwa-core-nuxt3/components/Upload.vue';
import type {
	IAPIUserExtra,
	IAPILoginUser,
	UserExtraType,
	APITypeObjectId,
	APIObjectType
} from '@hokify/common';
import ContextMenu from '@hokify/shared-components-nuxt3/lib/components/ContextMenu.vue';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import { useUserProfileStore } from '@/stores/user-profile';
import { useUserProfileExtraStore } from '@/stores/user-profile-extra';

function hasPrepareUploadDocument(obj: any): obj is {
	prepareUploadDocument(
		title: string,
		type: UserExtraType | string,
		forceUpload?: boolean,
		id?: APITypeObjectId<APIObjectType.UserExtra>
	): void;
} {
	return obj && typeof obj.prepareUploadDocument === 'function';
}

export default defineComponent({
	name: 'ChooseCvModal',
	components: {
		InfoBox,
		HorizontalButtons,
		Upload,
		ContextMenu
	},
	emits: ['cv-deleted', 'close'],
	data() {
		const cvType = 'upload' as 'hokify' | 'upload' | 'new-upload';

		return {
			cvType
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		cvId(): string | undefined {
			return this.userProfileStore.obj?.extras?.find(file => file.type === 'cv')?._id;
		},
		cvUrl(): string | undefined {
			return this.visibleCv?.url;
		},
		activeHokifyCv(): boolean {
			return this.visibleCv?.details === 'hokify' || false;
		},
		visibleCv(): IAPIUserExtra | undefined {
			return (
				this.userProfileStore.obj?.extras?.find(file => file.type === 'cv' && file.visible) ||
				undefined
			);
		},
		...mapStores(useUserProfileStore, useUserProfileExtraStore)
	},
	created() {
		this.resetCvSelection();
	},
	methods: {
		resetCvSelection() {
			if (this.activeHokifyCv) {
				this.cvType = 'hokify';
			} else {
				this.cvType = 'upload';
			}
		},
		switchCv(cvType: 'hokify' | 'upload' | 'new-upload') {
			this.cvType = cvType;
			this.$modal.hide('chooseCvWarning');

			if (this.cvType === 'upload' || this.cvType === 'new-upload') {
				if (hasPrepareUploadDocument(this.$refs.upload)) {
					this.$refs.upload.prepareUploadDocument('Lebenslauf', 'cv');
				}
			} else if (this.cvType === 'hokify') {
				this.doUseHokifyCv();
			}
		},
		async openChangeCvDesign() {
			const changeCvDesignPageComponent = markRaw(
				defineAsyncComponent(() => import('@/pages/pwa/profile/ChangeCvDesignPage.vue'))
			);
			try {
				await this.$page.push(
					changeCvDesignPageComponent,
					{},
					{
						mode: 'modal',
						name: 'changeCvDesign',
						on: {
							done: () => {
								this.$page.goBack();
							}
						}
					}
				);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async doUseHokifyCv() {
			try {
				await this.userProfileStore.useHokifyCv();
				this.$snack.success({
					text: 'Dein hokify Lebenslauf wird jetzt für Bewerbungen benutzt.'
				});
			} catch (err) {
				if (
					this.$isHokFetchResponseError(err) &&
					err.response?.data?.code === 'INCOMPLETE_HOKIFY_CV'
				) {
					this.$snack.danger({
						text: 'Um den hokify Lebenslauf verwenden zu können, muss dieser vollständiger sein!'
					});
					return;
				}

				this.$nuxt.$errorHandler(
					err,
					'Es ist ein Fehler aufgetreten, beim Versuch den hokify Lebenslauf zu verwenden.'
				);
			}
		},
		cancelCvSelection() {
			this.$modal.hide('chooseCvWarning');
			this.resetCvSelection();
		},
		async deleteCv() {
			try {
				if (this.cvId) {
					await this.userProfileExtraStore.delExtra(this.cvId);
					this.$snack.success({
						text: `Lebenslauf wurde erfolgreich gelöscht.`
					});
					this.$emit('cv-deleted');
				} else {
					this.$snack.danger({
						text: `Löschen fehlgeschlagen. Es ist kein Lebenslauf vorhanden.`
					});
				}
			} catch (err) {
				this.$snack.danger({
					text: `Löschen fehlgeschlagen. Bitte probiere es etwas später erneut.`
				});
				this.$nuxt.$errorHandler(err);
			}
		}
	},
	props: {
		profileOrMatchOverview: { type: Boolean, default: false },
		showCvMenu: { type: Boolean, default: false }
	}
});
</script>
